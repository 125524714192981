import Cookies from 'js-cookie';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'small'
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;

    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    Cookies.set('size', size);
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setSize: function setSize(_ref5, size) {
    var commit = _ref5.commit;
    commit('SET_SIZE', size);
  }
};
console.log('.__   __.      _______..__   __.  _______       ___   .___________.    ___      \n' + '|  \\ |  |     /       ||  \\ |  | |       \\     /   \\  |           |   /   \\     \n' + '|   \\|  |    |   (----`|   \\|  | |  .--.  |   /  ^  \\ `---|  |----`  /  ^  \\    \n' + '|  . `  |     \\   \\    |  . `  | |  |  |  |  /  /_\\  \\    |  |      /  /_\\  \\   \n' + "|  |\\   | .----)   |   |  |\\   | |  '--'  | /  _____  \\   |  |     /  _____  \\  \n" + '|__| \\__| |_______/    |__| \\__| |_______/ /__/     \\__\\  |__|    /__/     \\__\\ \n' + '                                                                                \n' + '\t\t\t\t\t\t      南叁南数据中心');
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};