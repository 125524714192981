var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view", { key: _vm.key })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.$store.state.settings.showFooter
        ? _c("div", { attrs: { id: "el-main-footer" } }, [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.$store.state.settings.footerTxt)
              }
            }),
            _vm._v(" "),
            _c("span", [_vm._v(" ⋅ ")]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: "http://www.beian.miit.gov.cn",
                  target: "_blank"
                }
              },
              [_vm._v(_vm._s(_vm.$store.state.settings.caseNumber))]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }