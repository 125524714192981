//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Logo from '@/assets/images/logo.png';
import LogoExpand from '@/assets/images/logoExpand.png';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '',
      logo: Logo,
      logoExpand: LogoExpand
    };
  }
};