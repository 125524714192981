import "D:\\JAVA\\workspace2\\nsndata-web\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "D:\\JAVA\\workspace2\\nsndata-web\\node_modules\\core-js\\modules\\es6.promise.js";
import "D:\\JAVA\\workspace2\\nsndata-web\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "D:\\JAVA\\workspace2\\nsndata-web\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui'; //

import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css'; // 数据字典

import dict from "./components/Dict"; // 权限指令

import checkPer from '@/utils/permission';
import permission from "./components/Permission";
import "./assets/styles/element-variables.scss"; // global css

import "./assets/styles/index.scss"; // 代码高亮

import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./utils/error-log"; // error log

import "./assets/icons"; // icon

import "./router/index"; // permission control

import 'echarts-gl'; // 引入echart

import echarts from 'echarts';
import AFTableColumn from 'af-table-column';
Vue.prototype.$echarts = echarts;
import VueCron from 'vue-cron';
import dataV from '@jiaminghi/data-view'; // 按需引入vue-awesome图标

import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import jquery from 'jquery';
Vue.prototype.$ = jquery;
import VueContextMenu from 'vue-contextmenu';
Vue.use(VueContextMenu); // 全局注册图标

Vue.component('icon', Icon);
Vue.use(dataV);
Vue.use(VueCron); // 使用方式：<vueCron></vueCron>

Vue.use(checkPer);
Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(dict);
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size

});
Vue.use(AFTableColumn);
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});